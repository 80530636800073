import { useEffect, useMemo } from 'react'
import { useRequest } from 'ahooks'

import type * as T from '../../LocalProductList'
import { apiGetRecordsBySupplier } from '../../LocalProductList'

export type TProduct = Pick<T.IRecord, 'skuId' | 'sku' | 'productEnTitle' | 'productType'>

/** 获取供应商本地产品列表 */
export const useProductList = (
  sysSupplierId?: string | null,
): {
  productList: TProduct[]
  productEnTitleMap: { [skuId: string]: string | null | undefined }
} => {
  const { data, run, cancel, mutate } = useRequest<TProduct[], [string]>(
    id =>
      apiGetRecordsBySupplier(id).then(res =>
        res.map(({ skuId, sku, productEnTitle, productType }) => ({
          skuId,
          sku,
          productEnTitle,
          productType,
        })),
      ),
    { manual: true },
  )

  useEffect(() => {
    cancel()
    mutate(undefined)
    if (sysSupplierId) run(sysSupplierId)
  }, [cancel, mutate, run, sysSupplierId])

  return useMemo(() => {
    return {
      productList: data || [],
      productEnTitleMap: (data || []).reduce((obj, d) => ({ ...obj, [d.skuId]: d.productEnTitle }), {}),
    }
  }, [data])
}
