import axios, { AxiosRequestConfig } from 'axios'

import { IDetail, IEntryResultRecord, IFormValues, IProduct, IReqData, IRes } from './interface'
import './__mock__'

/** 获取提货单列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  const { merchantUserIds } = params
  return axios
    .get('/linkhub_oms/pick_up/order/list', {
      params: {
        ...params,
        merchantUserIds: merchantUserIds?.length ? merchantUserIds : undefined,
      },
      signal,
    })
    .then(res => ({
      ...res.$data,
      records: res.$data?.records || [],
    }))
}

/** 获取提货结果 */
export const apiGetEntryResult = async (sysEntryOrderCode: string): Promise<IEntryResultRecord[]> => {
  return axios
    .get(`/linkhub_oms/stock/pickup`, { params: { pickUpNo: sysEntryOrderCode } })
    .then(res => res.$data || [])
}

/** 取消申请（仅商家侧） */
export const apiCancel = async (sysEntryOrderCode: string) => {
  await axios.post(`/linkhub_oms/pick_up/order/cancel/${sysEntryOrderCode}`)
}

/** 状态变更（仅员工侧） */
export const apiChangeState = async (sysEntryOrderCode: string, curStatus: 1 | 2, changeStatus: 2 | 3) => {
  await axios.put(`/linkhub_oms/pick_up/order/state/${sysEntryOrderCode}`, {
    curStatus,
    changeStatus,
  })
}

/** 获取详情 */
export const apiGetDetail = async (sysEntryOrderCode: string): Promise<IDetail> => {
  return axios.get(`/linkhub_oms/pick_up/order/detail/${sysEntryOrderCode}`).then(res => res.$data)
}

/** 提货单商品信息excel导入 */
export const apiExcelImport = async (sysSupplierId: string, orderLineExcel: File): Promise<IProduct[]> => {
  const formData = new FormData()
  formData.append('orderLineExcel', orderLineExcel)
  const res = await axios.post(`/linkhub_oms/pick_up/order/line/excel/${sysSupplierId}`, formData)
  return res.$data
}

/** 新增或编辑提货申请，传入 sysEntryOrderCode 则视为编辑 */
export const apiAddOrEdit = async (reqData: IFormValues, sysEntryOrderCode?: string) => {
  if (sysEntryOrderCode) {
    await axios.put(`/linkhub_oms/pick_up/order/${sysEntryOrderCode}`, reqData)
  } else {
    await axios.post(`/linkhub_oms/pick_up/order`, reqData)
  }
}

/** 标记完成（仅员工侧） */
export const apiMarkCompleted = async (
  reqData: Pick<IFormValues, 'takeDeliveryCost' | 'realTakeDeliveryCost'> & {
    sysEntryOrderCode: string
  },
) => {
  await axios.put(`/linkhub_oms/pick_up/order/mark`, reqData)
}
