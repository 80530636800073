import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Divider, Modal } from 'antd'

import { CusTable } from '@/components/CusTable'
import { apiGetEntryResult } from './apis'
import { IEntryResultRecord } from './interface'
import { popupSlot } from './utils'

interface IEntryResultModalProps {
  sysEntryOrderCode: string
}

const updatePopup = popupSlot.insert(null)

export const EntryResultModal = Object.assign(
  ({ sysEntryOrderCode, destroy }: IEntryResultModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const { loading, data } = useRequest<IEntryResultRecord[], [string]>(() => apiGetEntryResult(sysEntryOrderCode), {
      defaultParams: [sysEntryOrderCode],
    })

    return (
      <Modal
        title={
          <>
            {t('Delivery.ti-huo-jie-guo')}
            <Divider type="vertical" />
            {sysEntryOrderCode}
          </>
        }
        width={900}
        cancelText={t('2-common.guan-bi')}
        okButtonProps={{ style: { display: 'none' } }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <CusTable
          sticky
          rowKey="skuId"
          columns={[
            { title: t('Delivery.chan-pin-sku'), width: 120, render: (_v, { sku: v }) => v },
            { title: t('Delivery.ying-wen-pin-ming'), render: (_v, { productEnTitle: v }) => v },
            { title: t('Delivery.shi-shou-shu-liang'), width: 120, render: (_v, { amount: v }) => v },
            { title: t('Delivery.zheng-pin-shu-liang'), width: 120, render: (_v, { goodAmount: v }) => v },
            {
              title: t('Delivery.ci-pin-shu-liang'),
              width: 120,
              render: (_v, { badAmount: v }) => (v > 0 ? <span style={{ color: 'red' }}>{v}</span> : v),
            },
            {
              title: t('Delivery.ru-ku-ri-qi'),
              width: 100,
              render: (_v, { inDate: v }) => dayjs(v).format($F_YMD),
            },
          ]}
          loading={loading}
          dataSource={data}
        />
      </Modal>
    )
  },
  {
    open: (props: IEntryResultModalProps) => {
      updatePopup(<EntryResultModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
