import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemoizedFn, useRequest } from 'ahooks'
import { produce } from 'immer'
import dayjs from 'dayjs'
import { Button, Popover, Radio, Space } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { PlusOutlined } from '@ant-design/icons'

import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { getDefaultPaginationProps } from '@/utils/pagination'
import { MerchantSelect } from '../components/MerchantSelect'
import { apiGetRecords } from './apis'
import { ActionCell, EmployeeChangeStateCell } from './Cells'
import { StateShow } from './components/StateShow'
import { Ctx, ICtxVal, initialReqData } from './Ctx'
import { DetailDrawer } from './DetailDrawer'
import { IRecord, IReqData, IRes } from './interface'
import { popupSlot, STATE, useSTATE_NAME_MAP } from './utils'
import styles from './styles.module.less'

export const Delivery = ({ isEmployee = false }: { isEmployee?: boolean }) => {
  const { t } = useTranslation()
  const stateNameMap = useSTATE_NAME_MAP()
  const [state, setState] = useState(initialReqData.state)
  const [merchantUserIds, setMerchantUserIds] = useState(initialReqData.merchantUserIds)
  const [page, setPage] = useState(initialReqData.page)
  const [size, setSize] = useState(initialReqData.size)

  const reqData: IReqData = { state, merchantUserIds, page, size }
  const reqDataRef = useRef(reqData)
  reqDataRef.current = reqData
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
    mutate,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const refreshList = useMemoizedFn<ICtxVal['refreshList']>(toFirstPage => {
    if (toFirstPage === true) {
      setPage(1)
      getList({ ...reqData, page: 1 })
    } else {
      getList({ ...reqData })
    }
  })

  const changeRecord = useMemoizedFn<ICtxVal['changeRecord']>((sysEntryOrderCode, changedValues) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        for (const item of draft.records) {
          if (item.sysEntryOrderCode === sysEntryOrderCode) {
            Object.assign(item, changedValues)
            return
          }
        }
      })
    })
  })
  const deleteRecord = useMemoizedFn<ICtxVal['deleteRecord']>(sysEntryOrderCode => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        let index = -1
        for (const item of draft.records) {
          index++
          if (item.sysEntryOrderCode === sysEntryOrderCode) {
            draft.records.splice(index, 1)
            return
          }
        }
      })
    })
  })

  const columns = useMemo<ColumnType<IRecord>[]>(() => {
    return [
      {
        title: t('Delivery.ti-huo-id'),
        width: 130,
        render: (_v, { sysEntryOrderCode: v }) => v,
      },
      {
        title: t('Delivery.ti-huo-chan-pin'),
        render: (_v, { orderLines }) => {
          const nodes = orderLines.map((d, i) => (
            <span key={i}>
              {i !== 0 && ' '}
              {d.sku || d.skuId}
              <wbr />*<span style={{ color: 'red' }}>{d.skuQuantity}</span>
            </span>
          ))
          if (nodes.length <= 5) return <>{nodes}</>
          return (
            <Popover overlayStyle={{ maxWidth: 360 }} content={<>{nodes}</>}>
              {nodes.slice(0, 5)} <wbr />
              ...
            </Popover>
          )
        },
      },
      {
        title: t('Delivery.yu-ji-ti-huo-ri-qi'),
        width: 110,
        render: (_v, { predictDeliveryTime: v }) => v && dayjs(v).format($F_YMD),
      },
      {
        title: t('Delivery.cang-ku-ru-ku-ri-qi'),
        width: 110,
        render: (_v, { storageTime: v }) => v && dayjs(v).format($F_YMD),
      },
      {
        title: t('Delivery.shen-qing-ri-qi'),
        width: 110,
        render: (_v, { createdTime: v }) => dayjs(v).format($F_YMD),
      },
      {
        title: t('2-common.shang-jia-ming-cheng'),
        hidden: !isEmployee,
        render: (_v, { merchantUserName: v }) => v,
      },
      {
        title: t('Delivery.ti-huo-fei'),
        width: 110,
        render: (_v, { takeDeliveryCost: v }) => v,
      },
      {
        title: t('Delivery.ti-huo-zhuang-tai'),
        width: 110,
        render: (_v, record) => {
          // 员工侧 & (待审核 | 生产中)
          if (isEmployee && [1, 2].includes(record.state)) {
            return <EmployeeChangeStateCell record={record} />
          }
          return <StateShow state={record.state} />
        },
      },
      {
        title: t('2-common.cao-zuo'),
        width: 120,
        render: (_v, record) => <ActionCell record={record} />,
      },
    ]
  }, [isEmployee, t])

  return (
    <Ctx.Provider value={{ isEmployee, reqDataRef, refreshList, changeRecord, deleteRecord }}>
      <div className={styles.box}>
        <div className={styles.header}>
          <div className={styles.header_filter}>
            <Space>
              <Radio.Group
                buttonStyle="solid"
                value={state}
                onChange={e => {
                  const val = e.target.value
                  setState(val)
                  setPage(1)
                  getList({ ...reqData, state: val, page: 1 })
                }}
              >
                {[undefined, ...STATE].map(v => (
                  <Radio.Button key={v || -1} value={v}>
                    {stateNameMap[v ?? -1] || v}
                  </Radio.Button>
                ))}
              </Radio.Group>
              {isEmployee && (
                <MerchantSelect
                  style={{ width: 160 }}
                  value={merchantUserIds?.[0]}
                  onChange={val => {
                    const merchantUserIds = val ? [val] : undefined
                    setMerchantUserIds(merchantUserIds)
                    setPage(1)
                    getList({ ...reqData, merchantUserIds, page: 1 })
                  }}
                />
              )}
            </Space>
          </div>
          <div className={styles.header_action}>
            {!isEmployee && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() =>
                  DetailDrawer.open({
                    actionType: 'add',
                    sysEntryOrderCode: null,
                  })
                }
              >
                {t('Delivery.ti-huo-shen-qing')}
              </Button>
            )}
          </div>
        </div>

        <div className={styles.table}>
          <CusTable
            rowKey="sysEntryOrderCode"
            loading={{ spinning: loading, delay: SPIN_DELAY }}
            dataSource={data?.records}
            columns={columns}
            ref={tableRef}
            verticalFlexLayout
            pagination={{
              ...getDefaultPaginationProps(data?.total || 0),
              current: page,
              pageSize: size,
              onChange: (page, size) => {
                setPage(page)
                setSize(size)
                getList({ ...reqData, page, size })
              },
            }}
          />
        </div>

        <popupSlot.Slot />
      </div>
    </Ctx.Provider>
  )
}
