import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Button, Dropdown, message, Modal, theme } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { apiCancel, apiChangeState } from './apis'
import { StateShow } from './components/StateShow'
import { Ctx } from './Ctx'
import { DetailDrawer } from './DetailDrawer'
import { EntryResultModal } from './EntryResultModal'
import { IRecord } from './interface'
import { useSTATE_NAME_MAP } from './utils'

/** 员工侧进行状态变更单元格 */
export const EmployeeChangeStateCell = ({ record }: { record: IRecord }) => {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const stateNameMap = useSTATE_NAME_MAP()

  const reqDataRef = useContextSelector(Ctx, v => v.reqDataRef)
  const changeRecord = useContextSelector(Ctx, v => v.changeRecord)
  const deleteRecord = useContextSelector(Ctx, v => v.deleteRecord)
  const { sysEntryOrderCode, state } = record

  const [open, setOpen] = useState(false)
  const comfirmRef = useRef(false)

  if (state !== 1 && state !== 2) return null
  return (
    <Dropdown
      trigger={['click']}
      destroyPopupOnHide
      open={open}
      onOpenChange={o => {
        if (comfirmRef.current) return
        setOpen(o)
      }}
      menu={{
        items: (state === 1 ? ([2, 3] as const) : ([3] as const)).map(v => ({
          key: v,
          label: stateNameMap[v] || v,
          onClick: ({ key }) => {
            comfirmRef.current = true
            const changedState = +key as 2 | 3
            Modal.confirm({
              title: t('Delivery.que-ren-yao-bian-geng-zhuang-tai-wei-status-ma', {
                status: stateNameMap[changedState] || changedState,
              }),
              onCancel: () => {
                comfirmRef.current = false
                setOpen(false)
              },
              onOk: async () => {
                await apiChangeState(sysEntryOrderCode, state, changedState)
                message.success(t('2-common.cao-zuo-cheng-gong'))
                comfirmRef.current = false
                setOpen(false)
                if (reqDataRef.current.state === undefined) {
                  changeRecord(sysEntryOrderCode, { state: changedState })
                } else {
                  deleteRecord(sysEntryOrderCode)
                }
              },
            })
          },
        })),
      }}
    >
      <StateShow
        style={{ cursor: 'pointer' }}
        state={state}
        after={<EditOutlined style={{ color: token.colorLink }} />}
      />
    </Dropdown>
  )
}

/** 操作单元格 */
export const ActionCell = ({ record }: { record: IRecord }) => {
  const { t } = useTranslation()
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const reqDataRef = useContextSelector(Ctx, v => v.reqDataRef)
  const changeRecord = useContextSelector(Ctx, v => v.changeRecord)
  const deleteRecord = useContextSelector(Ctx, v => v.deleteRecord)
  const { sysEntryOrderCode, state } = record

  return (
    <>
      <Button type="link" onClick={() => DetailDrawer.open({ actionType: 'view', sysEntryOrderCode })}>
        {t('2-common.xiang-qing')}
      </Button>

      {/* 商家侧：待审核 */}
      {/* 员工侧：除已完成 */}
      {(!isEmployee ? state === 1 : state !== 7) && (
        <Button type="link" onClick={() => DetailDrawer.open({ actionType: 'edit', sysEntryOrderCode })}>
          {t('2-common.bian-ji')}
        </Button>
      )}

      {/* 商家侧：待审核 */}
      {!isEmployee && state === 1 && (
        <Button
          type="link"
          danger
          onClick={() => {
            Modal.confirm({
              title: t('Delivery.que-ding-yao-qu-xiao-gai-ti-huo-dan-ma'),
              content: sysEntryOrderCode,
              onOk: async () => {
                await apiCancel(sysEntryOrderCode)
                message.success(t('2-common.cao-zuo-cheng-gong'))
                if (reqDataRef.current.state === undefined) {
                  changeRecord(sysEntryOrderCode, { state: 6 })
                } else {
                  deleteRecord(sysEntryOrderCode)
                }
              },
            })
          }}
        >
          {t('2-common.qu-xiao')}
        </Button>
      )}

      {/* 已入库、已完成 */}
      {[5, 7].includes(state) && (
        <Button type="link" onClick={() => EntryResultModal.open({ sysEntryOrderCode })}>
          {t('Delivery.ti-huo-jie-guo')}
        </Button>
      )}
    </>
  )
}
