import { createContext } from 'use-context-selector'
import _ from 'lodash'

import { DEFAULT_PAGE_SIZE } from '@/utils/pagination'
import { IRecord, IReqData } from './interface'

export interface ICtxVal {
  /** 是否员工侧 */
  isEmployee: boolean
  /** 当前筛选条件 ref */
  reqDataRef: { readonly current: Readonly<IReqData> }
  /** useRequest 列表请求 run 封装 --- 持久化函数 */
  refreshList: (toFirstPage?: boolean) => void
  /** 变更行数据 --- 持久化函数 */
  changeRecord: (sysEntryOrderCode: string, changedValues: Partial<IRecord>) => void
  /** 删除行数据 --- 持久化函数 */
  deleteRecord: (sysEntryOrderCode: string) => void
}

export const initialReqData: IReqData = {
  state: undefined,
  merchantUserIds: undefined,
  page: 1,
  size: DEFAULT_PAGE_SIZE,
}

export const Ctx = createContext<ICtxVal>({
  isEmployee: false,
  reqDataRef: { current: initialReqData },
  refreshList: _.noop,
  changeRecord: _.noop,
  deleteRecord: _.noop,
})
