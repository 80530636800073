import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  FormProps,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Space,
  theme,
} from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { FormItemDatePicker } from '@/components/FormItemDateTime'
import { FormItemUpload } from '@/components/FormItemUpload'
import { Loading } from '@/components/Loading'
import { useFormAllValues } from '@/hooks/hooks'
import { filePick } from '@/utils/filePick'
import { fileValidate, formAutoTrimOnBlur } from '@/utils/utils'
import { ISupplierRecord, SupplierSelect } from '../../components/SupplierSelect'
import { apiAddOrEdit, apiExcelImport, apiGetDetail, apiMarkCompleted } from '../apis'
import { Ctx } from '../Ctx'
import { IDetail, IFormValues } from '../interface'
import { DELIVERY_METHOD, popupSlot, useDELIVERY_METHOD_NAME_MAP } from '../utils'
import { ProductSelect } from './ProductSelect'
import { useProductList } from './useProductList'
import { createEmptyProduct, createInitialFormValues, createSendValues, textInputFields } from './utils'

interface IDetailDrawerProps<T extends 'add' | 'edit' | 'view'> {
  actionType: T
  sysEntryOrderCode: T extends 'add' ? null : string
}

const updatePopup = popupSlot.insert(null)

export const DetailDrawer = Object.assign(
  <T extends 'add' | 'edit' | 'view'>({
    actionType,
    sysEntryOrderCode,
    destroy,
  }: IDetailDrawerProps<T> & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { token } = theme.useToken()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
    const refreshList = useContextSelector(Ctx, v => v.refreshList)
    const deliveryMethodNameMap = useDELIVERY_METHOD_NAME_MAP()

    const [fetching, setFetching] = useState(actionType !== 'add' && !!sysEntryOrderCode)
    const [detail, setDetail] = useState<IDetail | null>(null)
    const [supplierList, setSupplierList] = useState<ISupplierRecord[]>([])

    const [form] = Form.useForm<IFormValues>()
    const initialFormValues = useMemo(() => createInitialFormValues(null), [])
    const allValues = useFormAllValues(form, initialFormValues)
    const curSupplier = supplierList.find(d => d.sysSupplierId === allValues.sysSupplierId)
    const { productList, productEnTitleMap } = useProductList(allValues.sysSupplierId)

    const formProps: FormProps<IFormValues> = {
      form,
      scrollToFirstError: { block: 'center', behavior: 'smooth' },
      layout: 'vertical',
      disabled: actionType === 'view',
      preserve: true,
      initialValues: initialFormValues,
      onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: textInputFields }),
    }

    const employeeFormDisabled = formProps.disabled || isEmployee

    const getReqData = (): IFormValues => {
      const values = createSendValues(allValues)
      values.orderLines = values.orderLines.filter(d => d.effectiveFlag !== false && d.skuId && d.skuQuantity)
      return values
    }
    const validateOrderLines = (): boolean => {
      const { orderLines } = allValues
      for (const { effectiveFlag, skuId, skuQuantity } of orderLines) {
        if (effectiveFlag !== false) {
          if (!skuId || !skuQuantity) {
            message.error(t('Delivery.xiao-yan-bu-tong-guo-qing-jian-cha'))
            return false
          }
        }
      }
      if (!getReqData().orderLines.length) {
        message.error(t('Delivery.qing-lu-ru-you-xiao-chan-pin'))
        return false
      }
      return true
    }

    useEffect(() => {
      if (actionType === 'add' || !sysEntryOrderCode) return
      setFetching(true)
      apiGetDetail(sysEntryOrderCode)
        .then(res => {
          const values = createInitialFormValues(res)
          setDetail(res)
          form.setFieldsValue(values)
        })
        .finally(() => setFetching(false))
    }, [actionType, form, sysEntryOrderCode])

    return (
      <Drawer
        title={`${
          {
            ['add']: t('2-common.xin-zeng'),
            ['edit']: t('2-common.bian-ji'),
            ['view']: t('2-common.cha-kan'),
          }[actionType]
        } ${t('Delivery.ti-huo-shen-qing')}`}
        width={800}
        extra={
          <Space>
            {actionType === 'add' && (
              <Button
                type="primary"
                onClick={async () => {
                  form.submit() // 触发 scrollToFirstError
                  await form.validateFields()
                  if (!validateOrderLines()) return
                  Modal.confirm({
                    title: t('Delivery.que-ren-yao-xin-zeng-ma'),
                    onOk: async () => {
                      await apiAddOrEdit(getReqData())
                      message.success(t('2-common.cao-zuo-cheng-gong'))
                      onHide()
                      refreshList(true)
                    },
                  })
                }}
              >
                {t('2-common.xin-zeng')}
              </Button>
            )}
            {actionType === 'edit' && (
              <Button
                type="primary"
                disabled={!detail}
                onClick={async () => {
                  form.submit() // 触发 scrollToFirstError
                  await form.validateFields()
                  if (!validateOrderLines()) return
                  if (!sysEntryOrderCode) return
                  Modal.confirm({
                    title: t('Delivery.que-ren-yao-bao-cun-ma'),
                    onOk: async () => {
                      await apiAddOrEdit(getReqData(), sysEntryOrderCode)
                      message.success(t('2-common.cao-zuo-cheng-gong'))
                      onHide()
                      refreshList()
                    },
                  })
                }}
              >
                {t('2-common.bao-cun')}
              </Button>
            )}
            {actionType === 'edit' && isEmployee && detail?.state === 5 && (
              <Button
                type="primary"
                onClick={async () => {
                  const { takeDeliveryCost, realTakeDeliveryCost } = allValues
                  if (!takeDeliveryCost) return message.error(t('Delivery.qing-shu-ru-ti-huo-fei'))
                  if (!realTakeDeliveryCost) {
                    return message.error(t('Delivery.qing-shu-ru-shi-ji-ti-huo-fei'))
                  }
                  if (!sysEntryOrderCode) return
                  Modal.confirm({
                    title: t('Delivery.que-ren-yao-biao-ji-wan-cheng-ma'),
                    onOk: async () => {
                      await apiMarkCompleted({
                        takeDeliveryCost,
                        realTakeDeliveryCost,
                        sysEntryOrderCode,
                      })
                      message.success(t('2-common.cao-zuo-cheng-gong'))
                      onHide()
                      refreshList()
                    },
                  })
                }}
              >
                {t('Delivery.biao-ji-wan-cheng')}
              </Button>
            )}
            <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
          </Space>
        }
        open={open}
        onClose={onHide}
        afterOpenChange={afterOpenChange}
      >
        {fetching ? (
          <Loading />
        ) : (
          <>
            {isEmployee && (detail?.state === 5 || detail?.state === 7) && (
              <>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('Delivery.ti-huo-fei-yong')}</b>
                </Divider>
                <Form {...formProps} disabled={formProps.disabled || detail?.state === 7}>
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label={t('Delivery.ti-huo-fei')} name="takeDeliveryCost">
                        <Input placeholder={t('2-common.qing-shu-ru')} addonAfter="USD" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('Delivery.shi-ji-ti-huo-fei')} name="realTakeDeliveryCost">
                        <Input placeholder={t('2-common.qing-shu-ru')} addonAfter="USD" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </>
            )}

            <Divider orientation="left" orientationMargin={0}>
              <b>{t('Delivery.gong-ying-shang-xin-xi')}</b>
            </Divider>
            <Row gutter={12}>
              <Col span={8}>
                <Form {...formProps} disabled={employeeFormDisabled}>
                  <Form.Item
                    label={t('Delivery.gong-ying-shang')}
                    name="sysSupplierId"
                    rules={[{ required: true }]}
                    normalize={v => v || ''}
                  >
                    <SupplierSelect
                      style={{ maxWidth: 'auto' }}
                      allowClear={false}
                      merchantUserId={isEmployee ? `${detail?.userId}` : undefined}
                      onList={setSupplierList}
                      onChange={() => form.setFieldValue('orderLines', [])}
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col span={16}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  labelCol={{ span: 5 }}
                  label={<span style={{ color: token.colorTextLabel }}>{t('Delivery.lian-xi-ren-xing-ming')}</span>}
                >
                  {curSupplier?.contactName || '--'}
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  labelCol={{ span: 5 }}
                  label={<span style={{ color: token.colorTextLabel }}>{t('Delivery.lian-xi-ren-you-xiang')}</span>}
                >
                  {curSupplier?.contactEmail || '--'}
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  labelCol={{ span: 5 }}
                  label={<span style={{ color: token.colorTextLabel }}>{t('Delivery.lian-xi-ren-dian-hua')}</span>}
                >
                  {curSupplier?.contactPhone || '--'}
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 19 }}
                  label={<span style={{ color: token.colorTextLabel }}>{t('2-common.di-zhi')}</span>}
                >
                  {curSupplier?.address || '--'}
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" orientationMargin={0}>
              <b>{t('Delivery.ti-huo-xin-xi')}</b>
            </Divider>
            <Form {...formProps}>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label={t('Delivery.ti-huo-fang-shi')} name="deliveryMethod" rules={[{ required: true }]}>
                    <Radio.Group>
                      {DELIVERY_METHOD.map(v => (
                        <Radio key={v} value={v}>
                          {deliveryMethodNameMap[v] || v}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.yu-ji-ti-huo-ri-qi')} name="predictDeliveryTime">
                    <FormItemDatePicker format={$F_YMD} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('Delivery.yu-ji-dao-huo-ri-qi')}
                    name="predictArrivalTime"
                    rules={[{ required: isEmployee }]}
                  >
                    <FormItemDatePicker format={$F_YMD} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.wu-liu-gong-si')} name="logisticsCompany">
                    <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.wu-liu-dan-hao')} name="logisticsOddNumber">
                    <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.si-ji-ming-zi')} name="driverName">
                    <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.si-ji-dian-hua')} name="driverPhone">
                    <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.che-pai')} name="licensePlate">
                    <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Delivery.che-xing-hao')} name="typeOfCar">
                    <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('2-common.fu-jian')} name="attachmentUrl">
                    <FormItemUpload<false>
                      reqData={{ moduleType: 3 }}
                      maxCount={1}
                      beforeUpload={file => {
                        const msg = fileValidate(file, { max: 10 })
                        if (msg) {
                          message.error(msg)
                          return FormItemUpload.LIST_IGNORE
                        }
                      }}
                      initialNameMap={detail && { [detail.attachmentUrl || '']: detail.attachmentName }}
                      onChange={(_v, name) => form.setFieldValue('attachmentName', name)}
                    >
                      <Button icon={<UploadOutlined />}>{t('2-common.shang-chuan-fu-jian')}</Button>
                    </FormItemUpload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Divider orientation="left" orientationMargin={0} style={{ margin: '36px 0 0' }}>
              <b>{t('Delivery.chan-pin-xin-xi')}</b>
            </Divider>
            <Form {...formProps} disabled={employeeFormDisabled}>
              <CusTable
                style={{ minHeight: 260 }}
                rowKey={(...[{ skuId }, index]) => skuId || `${skuId}@$@${index}`}
                dataSource={allValues.orderLines}
                title={() => (
                  <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                    {actionType !== 'view' && !allValues.sysSupplierId && (
                      <span style={{ marginRight: 12, color: token.colorError }}>
                        {t('Delivery.qing-xian-xuan-ze-gong-ying-shang')}
                      </span>
                    )}
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusOutlined />}
                      disabled={employeeFormDisabled || !allValues.sysSupplierId}
                      onClick={() => {
                        filePick({
                          accept: '.xlsx',
                          onFilePick: ([file]) => {
                            const msg = fileValidate(file, { accept: '.xlsx', max: 10 })
                            if (msg) return void message.error(msg)

                            const { sysSupplierId, orderLines } = allValues
                            if (!sysSupplierId) return

                            Modal.confirm({
                              title: t('Delivery.que-ren-yao-dao-ru-ma'),
                              content: file.name,
                              onOk: async () => {
                                const list = await apiExcelImport(sysSupplierId, file)
                                const oldList = orderLines.filter(item =>
                                  list.every(({ skuId }) => skuId !== item.skuId),
                                )
                                form.setFieldValue('orderLines', list.concat(oldList))
                                message.success(t('2-common.cao-zuo-cheng-gong'))
                              },
                            })
                          },
                        })
                      }}
                    >
                      {t('2-common.excel-dao-ru')}
                    </Button>
                    <Button
                      size="small"
                      disabled={false}
                      onClick={() =>
                        window.open(`https://shipo-erp.oss-us-east-1.aliyuncs.com/excelTemplate/EntryOrderLineTemplate.xlsx
                      `)
                      }
                    >
                      {t('2-common.excel-mo-ban-xia-zai')}
                    </Button>
                  </Space>
                )}
                columns={[
                  {
                    title: t('Delivery.you-xiao-xing'),
                    align: 'center',
                    width: 80,
                    render: (_v, { effectiveFlag }) => {
                      if (effectiveFlag === false) {
                        return (
                          <Popover mouseLeaveDelay={0} content={t('Delivery.dao-ru-de-wu-xiao-chan-pin-tip')}>
                            <CloseCircleOutlined style={{ color: token.colorError }} />
                          </Popover>
                        )
                      }
                      return <CheckCircleOutlined style={{ color: token.colorSuccess }} />
                    },
                  },
                  {
                    title: 'SKU',
                    width: 200,
                    render: (_v, { skuId, sku, skuQuantity }, index) => {
                      return (
                        <Form.Item noStyle>
                          <ProductSelect
                            style={{ width: 180 }}
                            productList={productList}
                            skuId={skuId}
                            sku={sku}
                            disableSkuIds={allValues.orderLines.map(d => d.skuId)}
                            onPick={product => {
                              const newList = [...allValues.orderLines]
                              newList.splice(index, 1, { ...product, skuQuantity })
                              form.setFieldValue('orderLines', newList)
                            }}
                          />
                        </Form.Item>
                      )
                    },
                  },
                  {
                    title: t('Delivery.ying-wen-pin-ming'),
                    render: (_v, { skuId, productEnTitle }) => {
                      return productEnTitle || productEnTitleMap[skuId] || '--'
                    },
                  },
                  {
                    title: t('2-common.shu-liang'),
                    width: 100,
                    render: (_v, { effectiveFlag }, index) => {
                      return (
                        <Form.Item
                          noStyle
                          name={['orderLines', index, 'skuQuantity']}
                          rules={[{ required: effectiveFlag ?? true }]}
                        >
                          <InputNumber
                            style={{ width: 80 }}
                            min={1}
                            precision={0}
                            disabled={employeeFormDisabled || effectiveFlag === false}
                          />
                        </Form.Item>
                      )
                    },
                  },
                  {
                    title: (
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        icon={<PlusOutlined />}
                        disabled={employeeFormDisabled || !allValues.sysSupplierId}
                        onClick={() => {
                          const list = [...allValues.orderLines]
                          list.unshift(createEmptyProduct())
                          form.setFieldValue('orderLines', list)
                        }}
                      >
                        {t('2-common.tian-jia')}
                      </Button>
                    ),
                    align: 'center',
                    width: 90,
                    render: (_v, _r, index) => {
                      return (
                        <Popconfirm
                          title={t('2-common.que-ren-yao-shan-chu-ma')}
                          onConfirm={() => {
                            const newList = [...allValues.orderLines]
                            newList.splice(index, 1)
                            form.setFieldValue('orderLines', newList)
                          }}
                        >
                          <Button type="link" danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                      )
                    },
                  },
                ]}
              />
            </Form>
          </>
        )}
      </Drawer>
    )
  },
  {
    open: <T extends 'add' | 'edit' | 'view'>(props: IDetailDrawerProps<T>) => {
      updatePopup(<DetailDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
