import { IDetail, IFormValues, IProduct } from '../interface'

/** 文本输入字段 */
export const textInputFields = [
  'takeDeliveryCost',
  'realTakeDeliveryCost',
  'logisticsCompany',
  'logisticsOddNumber',
  'driverName',
  'driverPhone',
  'licensePlate',
  'typeOfCar',
]

/** 根据详情创建初始的表单数据 */
export const createInitialFormValues = (data: IDetail | null): IFormValues => {
  // 默认值尽可能使用空串
  const values: IFormValues = {
    takeDeliveryCost: '',
    realTakeDeliveryCost: '',
    sysSupplierId: '',
    deliveryMethod: 2,
    predictDeliveryTime: '',
    predictArrivalTime: '',
    logisticsCompany: '',
    logisticsOddNumber: '',
    driverName: '',
    driverPhone: '',
    licensePlate: '',
    typeOfCar: '',
    attachmentUrl: '',
    attachmentName: '',
    orderLines: [],
  }
  if (data) {
    const vals = values as any
    const dat = data as any
    Object.keys(vals).forEach(key => {
      if (dat[key] != null) {
        vals[key] = dat[key] // 赋值
      }
    })
  }
  return values
}

/** 根据表单数据创建提交数据 */
export const createSendValues = ({ ...formValues }: IFormValues): IFormValues => {
  const emptyValues = createInitialFormValues(null)
  Object.entries(emptyValues).forEach(([key, val]) => {
    if (typeof val === 'string') {
      const obj = formValues as any
      obj[key] = obj[key] || ''
    }
  })
  return formValues
}

/** 创建空的产品数据 */
export const createEmptyProduct = (): IProduct => {
  return {
    skuId: '',
    sku: null,
    skuQuantity: 1,
  }
}
