import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { createPopupSlot } from '@/services/popupSlot'

export const popupSlot = createPopupSlot()

/** 提货状态值列表 */
export const STATE = [1, 2, 3, 4, 5, 6, 7] as const

/** 提货状态值:名称 */
export const useSTATE_NAME_MAP = (): Record<number | '-1', string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      '-1': t('2-common.quan-bu'),
      1: t('Delivery.dai-shen-he'),
      2: t('Delivery.sheng-chan-zhong'),
      3: t('Delivery.yun-shu-zhong'),
      4: t('Delivery.ru-ku-zhong'),
      5: t('Delivery.yi-ru-ku'),
      6: t('Delivery.yi-qu-xiao'),
      7: t('Delivery.yi-wan-cheng'),
    }),
    [t],
  )
}

/** 提货方式值列表 */
export const DELIVERY_METHOD = [1, 2] as const

/** 提货方式值:名称 */
export const useDELIVERY_METHOD_NAME_MAP = (): Record<number, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      1: t('Delivery.gong-ying-shang-zi-song'),
      2: t('Delivery.shipo-ti-huo'),
    }),
    [t],
  )
}
